import React, { createContext, useContext, useState, ReactElement, useEffect } from 'react';
import { SvgIconComponent } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { RoutesEnum } from '../constants';
import {
  LayersTripleOutline,
  ViewGridOutline,
  CalendarRangeOutline,
  TicketPercentOutline,
  SubtitlesOutline,
  ClockOutline,
  MapMarkerOutline,
  FileDocumentOutline,
  MessageTextOutline,
  ShieldSearch,
  Logout
} from 'mdi-material-ui';
import DashboardPage from '../pages/portal-patient/dashboard';
import MyMedicinesPage from '../pages/portal-patient/my-medicines/pages/MyMedicinesPage';
import SchedulesPage from '../pages/portal-patient/schedules';
import DiscountsPage from '../pages/portal-patient/discounts';
import ContentsPage from '../pages/portal-patient/contents';
import PurchasePage from '../pages/portal-patient/purchase';
import RegulamentPage from '../pages/portal-patient/regulament';
import MedicineLeafletsPage from '../pages/portal-patient/medicine-leaflets/pages/MedicineLeafletsPage';
import FaqPage from '../pages/portal-patient/faq';
import AccreditedPage from '../pages/portal-patient/accredited/pages/AccreditedPage';
import { localStorageMethods } from '../pages/portal-patient/common/helpers';
import { IPatientCrendentials, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';

type Route = {
  icon?: SvgIconComponent;
  label?: string;
  route: string;
  sidebar?: boolean;
  quickAccess?: boolean;
  dashboard?: boolean;
  exact: boolean;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  onClick?: () => void;
};

export type RoutesContextState = {
  default: string;
  routes: Route[];
};

export type RoutesContextProps = {
  children: ReactElement;
};

const defaultState: RoutesContextState = {
  default: '',
  routes: [],
};

const addSufix = (route: string, sufix: string): string => {
  return route.endsWith(sufix) ? route : `${route}${sufix}`;
};

const sufix = '/unimed';

const routes: {
  [key: string]: Route;
} = {
  [RoutesEnum.PATIENT_PANEL_DASHBOARD]: {
    icon: ViewGridOutline,
    label: 'Home portal',
    route: RoutesEnum.PATIENT_PANEL_DASHBOARD,
    sidebar: true,
    dashboard: true,
    component: DashboardPage,
    exact: false,
  },
  [RoutesEnum.PATIENT_MEDICATION_ROUTE]: {
    icon: LayersTripleOutline,
    label: 'Meus medicamentos',
    route: RoutesEnum.PATIENT_MEDICATION_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: MyMedicinesPage,
    exact: false,
  },
  [RoutesEnum.PATIENT_SCHEDULES_ROUTE]: {
    icon: CalendarRangeOutline,
    label: 'Meus agendamentos',
    route: RoutesEnum.PATIENT_SCHEDULES_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: SchedulesPage,
    exact: false,
  },
  [RoutesEnum.PATIENT_DISCOUNTS_ROUTE]: {
    icon: TicketPercentOutline,
    label: 'Meus descontos',
    route: RoutesEnum.PATIENT_DISCOUNTS_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: DiscountsPage,
    exact: false,
  },
  [RoutesEnum.PATIENT_CONTENTS_ROUTE]: {
    icon: SubtitlesOutline,
    label: 'Conteúdos',
    route: RoutesEnum.PATIENT_CONTENTS_ROUTE,
    sidebar: true,
    component: ContentsPage,
    exact: false,
  },
  [RoutesEnum.PATIENT_PROGRAM_PURCHASE_ROUTE]: {
    icon: ClockOutline,
    label: 'Programe sua compra',
    route: RoutesEnum.PATIENT_PROGRAM_PURCHASE_ROUTE,
    sidebar: true,
    component: PurchasePage,
    exact: true,
  },
  [RoutesEnum.PATIENT_ACCREDITED_ROUTE]: {
    icon: MapMarkerOutline,
    label: 'Encontre uma loja',
    route: RoutesEnum.PATIENT_ACCREDITED_ROUTE,
    sidebar: true,
    component: AccreditedPage,
    exact: true,
  },
  [RoutesEnum.PATIENT_REGULAMENT_ROUTE]: {
    icon: ShieldSearch,
    label: 'Regulamento do programa',
    route: RoutesEnum.PATIENT_REGULAMENT_ROUTE,
    sidebar: true,
    component: RegulamentPage,
    exact: true,
  },
  [RoutesEnum.PATIENT_MEDICINE_LEAFLETS_ROUTE]: {
    icon: FileDocumentOutline,
    label: 'Bula de medicamentos',
    route: RoutesEnum.PATIENT_MEDICINE_LEAFLETS_ROUTE,
    sidebar: true,
    component: MedicineLeafletsPage,
    exact: true,
  },
  [RoutesEnum.PATIENT_FAQ_ROUTE]: {
    icon: MessageTextOutline,
    label: 'Perguntas frequentes',
    route: RoutesEnum.PATIENT_FAQ_ROUTE,
    sidebar: true,
    component: FaqPage,
    exact: true,
  },
  [RoutesEnum.LOGOUT_ROUTE]: {
    icon: Logout,
    label: 'Sair do sistema',
    route: RoutesEnum.LOGOUT_ROUTE,
    sidebar: true,
    component: ContentsPage,
    exact: true,
    onClick: () => {
      window.location.href = `${RoutesEnum.PATIENT_LOGIN_PAGE}${localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS)?.isUnimed ? 'unimed' : ''}`;  
      localStorageMethods.removeItem(LocalStorageKeysEnum.PATIENT_CREDENTIALS)
    },
  },
};

export const RoutesContext = createContext<RoutesContextState>(defaultState);

export const RoutesProvider = ({ children }: RoutesContextProps): JSX.Element => {
  const [state, setState] = useState<RoutesContextState>(defaultState);

  useEffect(() => {
    const routesArray = Object.values(routes);
    const credentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);

    const updatedRoutes = routesArray.map(route => ({
      ...route,
      route: credentials?.isUnimed ? addSufix(route.route, sufix) : route.route,
    }));

    if (updatedRoutes && updatedRoutes.length) {
      setState({
        default: updatedRoutes[0].route,
        routes: updatedRoutes,
      });
    }
  }, []);

  return <RoutesContext.Provider value={state}>{children}</RoutesContext.Provider>;
};


export const useRoutes = (): RoutesContextState => useContext<RoutesContextState>(RoutesContext);
