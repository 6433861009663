/* eslint-disable */

import React, { useState } from 'react';
import { Typography, Container, Box, Grid, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { InsertDriveFileOutlined, DateRangeOutlined, ConfirmationNumberOutlined, SearchOutlined } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

const noticias = [
  {
    title: "O que é diabetes?",
    content: "Quantas vezes você já ouviu falar que o açúcar em excesso faz mal para a saúde? Apesar de essa ser uma informação já conhecida por muitas pessoas, o conhecimento sobre o que é o diabetes de fato ainda é escasso.",
    fileName: 'contents/o-que-e-diabetes',
  },
  {
    title: "Mitos e verdades sobre alimentação e diabetes",
    content: "Quem convive com o diabetes sabe que não faltam dicas e receitas milagrosas que prometem controlar a glicemia. Mas afinal, o que realmente é verdade e o que é mentira? Veja abaixo alguns mitos e verdades sobre o assunto:",
    fileName: 'contents/mitos-e-verdades-sobre-alimentacao-e-diabetes',
  },
  {
    title: "Todo movimento conta",
    content: "Você quer incorporar a atividade física na sua rotina, mas não sabe o que fazer nem por onde começar?",
    fileName: 'contents/todo-movimento-conta',
  },
  {
    title: "Como tratar a hipoglicemia",
    content: "A hipoglicemia é uma complicação aguda que se caracteriza por níveis baixos de glicose no sangue. Ocorre uma queda na concentração da glicose para níveis abaixo de 70 mg/dl. É mais comum acontecer em pessoas...",
    fileName: 'contents/como-tratar-a-hipoglicemia',
  },
  {
    title: "Os sete comportamentos do autocuidado",
    content: "O autocuidado pode ser definido através de atividades que o indivíduo realiza em benefício de si próprio. Essas atividades estão relacionadas ao bem-estar e à promoção da saúde. A facilidade para essas atividades...",
    fileName: 'contents/os-sete-comportamentos-do-autocuidado',
  },
  {
    title: "Dieta DASH: alimentação saudável para hipertensos 1-3",
    content: "Que a alimentação faz toda diferença na sua saúde você já deve saber, certo? Mas você sabia que é possível reduzir a pressão arterial com mudanças na dieta diária?",
    fileName: 'contents/dieta-dash-alimentacao-saudavel-para-hipertensos-1-3',
  },
  {
    title: "O que é hipertensão arterial?",
    content: "A pressão arterial é um daqueles assuntos de saúde que todo mundo acha que entende um pouco. Afinal, quem nunca sentiu uma fraqueza e logo pensou “minha pressão deve estar baixa”?",
    fileName: 'contents/o-que-e-hipertensao-arterial',
  },
  {
    title: "Tratamento para hipertensão",
    content: "Ser hipertenso significa que o coração tem que trabalhar mais para conseguir vencer os altos valores de pressão. Esse trabalho extra faz com que as chances de ter complicações como um acidente vascular cerebral...",
    fileName: 'contents/tratamento-para-hipertensao',
  },
];

const DashboardPage: React.FC<any> = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState(noticias);
  const origin = window.location.pathname.includes('/unimed') ? '/unimed' : '';

  const handleSearchChange = (event: { target: { value: string; }; }) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = noticias.filter(news =>
      news.title.toLowerCase().includes(term) || news.content.toLowerCase().includes(term)
    );
    setFilteredNews(filtered);
  };

  return (
    <>
      <Container>

        <Box my={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
            Acesso rápido
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '24px 0 36px 0' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Link to={`/panel/my-medicines${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<InsertDriveFileOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br />medicamentos
                  </div>
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Link to={`/panel/schedules${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<DateRangeOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br />agendamentos
                  </div>
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Link to={`/panel/discounts${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<ConfirmationNumberOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br /> descontos
                  </div>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box my={4}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left', marginBottom: '12px' }}>
                Meus agendamentos
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/panel/schedules${origin}`} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" style={{ color: 'var(--primary-main)', cursor: 'pointer' }}>
                  Exibir mais
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Paper variant="outlined" style={{ padding: '16px', marginTop: '16px', borderRadius: '8px' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Consultas</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Nome do especialista</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Data</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Horário</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center" style={{ padding: '32px 0px' }}>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Em breve as informações de agendamento estarão disponíveis.
                      </Typography>
                      <Typography variant="body2">
                        Por enquanto, para falar sobre agendamento, entre em contato com 0800 701 6633
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        <Box my={4} py={3}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                Conteúdos
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/panel/contents${origin}`} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" style={{ color: 'var(--primary-main)', cursor: 'pointer' }}>
                  Exibir mais
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Box my={2} py={2}>
            <TextField
              fullWidth
              placeholder="Busque e explore nosso banco de conteúdos"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ backgroundColor: '#fff' }}
              InputProps={{
                startAdornment:
                  <Box>
                    <SearchOutlined />
                  </Box>
              }}
            />
          </Box>
          <Grid container spacing={5}>
            {filteredNews.map((news, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link to={`/panel/${news.fileName}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" style={{ color: '#556275' }}>
                    {news.content}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default DashboardPage;
